


























import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import { Filter, FilterSiteValue, Site } from '@/api/CommonWrapper/types'

interface Item {
  id: number
  name: string
  icon: string
  text: string[]
}

@Component({ name: 'LandingPlatform', components: { LandingTitle } })
export default class LandingPlatform extends Vue {
  list: Item[] = []

  sites: Site[] = []
  filters: Filter[] = []

  private async created() {
    let querySites = await this.$api.common.getSitesList()
    this.sites = querySites.data.filter(function (item) {
      return item.id !== 1
    })

    let queryFilters = await this.$api.common.getFiltersList()
    this.filters = queryFilters.data

    const types = this.filters.find((item) => item.type === 'type')

    this.list = this.sites.map((site) => {
      const text = (types!.values as FilterSiteValue[])
        .filter((type) => {
          return type.sites.some((subsite) => subsite.id === site.id)
        })
        .map((result) => result.name)
      //.slice(0, 4)
      //.join(', ')

      return {
        id: site.id,
        name: site.tab_title,
        icon: site.icon,
        text,
      }
    })
  }
}
